import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable, catchError, from, map, of } from 'rxjs';
import { CommonService } from '../common/common.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageCacheService {
  
  constructor(
    private swUpdate: SwUpdate,
    private http: HttpClient,
    private commonService: CommonService
  ) { }

  async cacheImage(url: string, blobUrl: string): Promise<void> {
    console.log("SERVICE WORKER ENABLED")
    console.log(this.swUpdate.isEnabled)
    if (this.swUpdate.isEnabled) {
      // Register a service worker update
      try {
        const cache = await caches.open('image-cache');
        await cache.put(url, new Response(blobUrl));
      } catch (error) {
        console.error('Failed to cache image:', error);
      }
      
    }
  }

  async getImageFromCache(url: string): Promise<string | null> {
    if (!this.swUpdate.isEnabled) {
      return null;
    }

    try {
      const cache = await caches.open('image-cache');
      const response = await cache.match(url);
      if (response) {
        return response.text(); // Return the Blob URL
      }
      return null;
    } catch (error) {
      console.error('Error retrieving image from cache:', error);
      return null;
    }
  }
  
  async getCachedImageUrls(): Promise<string[]> {
    if (!this.swUpdate.isEnabled) {
      return []; // Service worker is not enabled
    }

    const cache = await caches.open('image-cache');
    const cachedRequests = await cache.keys();

    return cachedRequests.map(request => request.url);
  }

  async clearImageCache(): Promise<void> {
    if (this.swUpdate.isEnabled) {
      const cacheNames = await caches.keys();
      for (const cacheName of cacheNames) {
        if (cacheName === 'image-cache') {
          await caches.delete(cacheName);
          // console.log('Image cache cleared');
          break;
        }
      }
    }
  }

  fetchAndCacheImage(url: string): Observable<string> {
    // Assuming fetch API is used to get image data from the server
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((blob: Blob) => {
        const blobUrl = URL.createObjectURL(blob);
        this.commonService.cachedImages[url] = blobUrl;
        return blobUrl;
      })
    );
  }

  getImage(url: string): Observable<string> {
    console.log("KEY");
    if (this.commonService.cachedImages[url]) {
      console.log("FROM CACHED")
      return of(this.commonService.cachedImages[url]);
    } else {
      console.log("FROM FETCHED")
      return this.fetchAndCacheImage(url);
    }
  }

}
