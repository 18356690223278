export const environment = {
  apiUrl: 'https://api-tripzinga.demo.ourproject.work/',
  baseUrl: 'https://tripzinga.demo.ourproject.work/',
  forumUrl: 'https://forum.tripzinga.com/',
  excludeHeaderFooterForRoutes: '',
  googlePlaceApiKey: 'AIzaSyD0LkkoELGq8Z5JVZE24Do8Ujm8YFzsjK4',
  googlePlaceApiUrl: 'https://maps.googleapis.com/maps/api/place/',
  recaptcha: {
    siteKey: '6LdXEfUpAAAAADXrnrFJK83s-NbFp-yW6qhNFUWS',
  },
};
