import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.getCurrentUser()
  );
  currentUser$ = this.currentUser.asObservable();

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.authenticated()
  );
  loggedIn$ = this.loggedIn.asObservable();

  private currentSubscription: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.getCurrentSubscription()
  );
  currentSubscription$ = this.currentSubscription.asObservable();

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    // private cookieService: CookieService
  ) { }

  authenticated(): boolean {
    if (this.getAuthorizationToken()) return true;
    return false;
  }
  getAuthorizationToken(): string | boolean {
    let token: string | boolean = false;
    if (isPlatformBrowser(this.platformId)) token = localStorage.getItem('accessToken') ?? false;
    return token;
  }

  setAuthorizationToken(token: string) {
    if (isPlatformBrowser(this.platformId)) localStorage.setItem('accessToken', token);
    this.loggedIn.next(true);
  }

  setLoggedIn(isLoggedIn: boolean = true) {
    this.loggedIn.next(isLoggedIn);
    sessionStorage.clear();
  }

  setCurrentUser(currentUser: any) {
    if (isPlatformBrowser(this.platformId)) localStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.currentUser.next(currentUser);
  }

  getCurrentUser() {
    let user: any = {};
    if (isPlatformBrowser(this.platformId)) {
      const getUser = localStorage.getItem('currentUser');
      if (getUser) user = JSON.parse(getUser);
    }
    return user;
  }

  setCurrentSubscription(currentSubscription: any) {
    if (isPlatformBrowser(this.platformId)) localStorage.setItem('currentSubscription', JSON.stringify(currentSubscription));
    this.currentSubscription.next(currentSubscription);
  }

  getCurrentSubscription() {
    let subscription: any = {};
    if (isPlatformBrowser(this.platformId)) {
      const getSubscription = localStorage.getItem('currentSubscription');
      if (getSubscription) subscription = JSON.parse(getSubscription);
    }
    return subscription;
  }

  signOut() {
    if (isPlatformBrowser(this.platformId)) localStorage.clear();
    // this.cookieService.delete('accessToken');
    this.loggedIn.next(false);
    this.currentUser.next(null);
    this.router.navigate(['']);
  }

}
