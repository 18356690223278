import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Input, Renderer2, Inject, PLATFORM_ID, Output, EventEmitter } from '@angular/core';
import { ImageCacheService } from '../../helpers/image-cache/image-cache.service';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Directive({
  selector: '[lazyLoadImage]',
  standalone: true
})
export class LazyLoadImageDirective {

  @Input() placeholder: string = '';
  @Input() serverImageUrl: string = '';
  @Input() fetch: number = 0;
  @Input() sizeKey: string = '';
  @Output() handleImageFetch: EventEmitter<any> = new EventEmitter();
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    private imageCacheService: ImageCacheService,
    private http: HttpClient,
  ) { }

  // ngAfterViewInit() {
  //   //  console.log(this.serverImageUrl)
  //   if (this.placeholder != '') {
  //     this.renderer.setAttribute(this.el.nativeElement, 'src', this.placeholder);
  //   }
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.renderer.setAttribute(this.el.nativeElement, 'src', this.serverImageUrl);
  //     const imageElement: HTMLImageElement = this.el.nativeElement;
  //     imageElement.onload = () => {
  //       // console.log("IMAGE ON LOAD")
  //       // Image loaded successfully, perform actions if needed
  //       // this.renderer.setAttribute(this.el.nativeElement, 'src', this.serverImageUrl);
  //       this.renderer.setAttribute(this.el.nativeElement, 'loading', 'eager');
  //       this.imageCacheService.cacheImage(this.serverImageUrl);
  //       // this.loadCachedImages();
  //     };
  //     imageElement.onerror = () => {
  //       // Error loading image
  //       // console.log('Error loading image');
  //     };
  //   }
  // }

  // async loadCachedImages(): Promise<void> {
  //   let cachedImageUrls = await this.imageCacheService.getCachedImageUrls();
  //   // console.log(cachedImageUrls)
  // }

  // ngAfterViewInit() {
  //   if (this.placeholder != '') {
  //     this.renderer.setAttribute(this.el.nativeElement, 'src', this.placeholder);
  //   }
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.imageCacheService.getImage(this.serverImageUrl).subscribe(
  //       (blobUrl: string) => {
  //         // Here you can use the imageBlob as needed, e.g., display it or save it.
  //         console.log('Fetched image as Blob:', blobUrl);
  //         if (blobUrl) {
  //           this.renderer.setAttribute(this.el.nativeElement, 'src', blobUrl);
  //           this.renderer.setAttribute(this.el.nativeElement, 'loading', 'eager');
  //         }

  //       },
  //       (error) => {
  //         console.error('Error fetching image:', error);
  //       }
  //     );
  //   }
  // }

  ngAfterViewInit() {
    // console.log('IMAGE URI >> ' + this.serverImageUrl)
    // console.log('FETCH >>' + this.fetch)
    if (this.placeholder != '') {
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.placeholder);
    }
    // if (isPlatformBrowser(this.platformId)) {
      if(this.fetch == 1){
        this.http.get(this.serverImageUrl).subscribe((result: any) => {
          // console.log("RESULT")
          // console.log(result)
          this.serverImageUrl = result[this.sizeKey];
          this.loadImage();
          this.handleImageFetch.emit(result);
        })
        // console.log(fetchImage);
      }else{
        this.loadImage();
      }
      
    // }
  }

  loadImage(){
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.serverImageUrl);
    const imageElement: HTMLImageElement = this.el.nativeElement;
    imageElement.onload = () => {
      this.renderer.setAttribute(this.el.nativeElement, 'loading', 'eager');
    };
    imageElement.onerror = () => {
      // Error loading image
      console.log('Error loading image');
    };
  }

 

}
