import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    // targetElement is a parameter of type HTMLElement, which represents the element that was clicked on.
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    // Only emit clickOutside if the click is outside the element
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }


}
