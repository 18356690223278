import { Injectable, LOCALE_ID, Inject, PLATFORM_ID, } from '@angular/core';
import { formatDate, getCurrencySymbol, isPlatformBrowser } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})

export class CommonService {

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private messageService: MessageService
  ) { }

  private loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loader$ = this.loader.asObservable();


  showLoader() {
    this.loader.next(true);

  }
  hideLoader() {
    this.loader.next(false);

  }

  private toast: BehaviorSubject<any> = new BehaviorSubject<any>({});
  toast$ = this.toast.asObservable();


  showSuccess(message: string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message
    });
    this.toast.next({ success: true, message: message });
  }

  showInfo(message: string) {
    this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: message
    });
    this.toast.next({ success: true, message: message });
  }
  
  showWarn(message: string) {
    this.messageService.add({
      severity: 'warn',
      summary: 'Warning',
      detail: message,
      life: 5000,
    });
    this.toast.next({ success: true, message: message });
  }

  showError(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message
    });
    this.toast.next({ success: false, message: message });
  }

  private replayData: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  replayData$ = this.replayData.asObservable();

  setReplayData(replayData: any) {
    this.replayData.next(replayData);
    // console.log("replayData from service", replayData);
  }


  setBetaMessageCrossed() {
    if (isPlatformBrowser(this.platformId)) sessionStorage.setItem('betaMessageCrossed', '1');
  }

  isBetaMessageCrossed() {
    let betaMessageCrossed = isPlatformBrowser(this.platformId) ? sessionStorage.getItem('betaMessageCrossed') : null;
    return betaMessageCrossed != null ? true : false;
  }

  setHelpTipsMessage(){
    if (isPlatformBrowser(this.platformId)) sessionStorage.setItem('helpTipsMessage', '1');
  }
  
  isHelpTipsMessage(){
    let helpTipsMessage = isPlatformBrowser(this.platformId) ? sessionStorage.getItem('helpTipsMessage') : null;
    return helpTipsMessage != null ? true : false;
  }

  formatDate(date: string, format: string = 'MM/dd/Y') {
    let dateObj = new Date(date + 'T00:00:00');
    let formattedDate = formatDate(dateObj, format, this.locale);
    // console.log(formattedDate);
    return formattedDate;
  }

  converDateString(date: string, format: string = 'MM/dd/Y') {
    let dateObj = new Date(date);
    let formattedDate = formatDate(dateObj, format, this.locale);
    // console.log(formattedDate);
    return formattedDate;
  }

  convertTimestampToDateFormat(timestamp: number, format: string = 'MM/dd/Y'){
    const date = new Date(timestamp * 1000);
    let formattedDate = formatDate(date, format, this.locale);
    return formattedDate;
  }
  formatTimestampToDate(timestamp: number, format: string = 'MM/dd/Y'){
    const date = new Date(timestamp * 1000);
    date.setDate(date.getDate() + 1);
    let formattedDate = formatDate(date, format, this.locale);
    return formattedDate;
  }
  
  getDatesInRange(startDate: any, endDate: any) {
    let startDateObj = new Date(startDate);
    let endDateObj = new Date(endDate);
    const currentDate = new Date(startDateObj.getTime());
    let dates: any = [];
    while (currentDate <= endDateObj) {
      let date = new Date(currentDate).toISOString();
      // console.log(date)
      let dateArr = date.split('T');
      dates.push(dateArr[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }
  // formateMonth() {
  //   let months = [];
  //   const currentDate = new Date();
  //   const currentYear = currentDate.getFullYear();
  //   for (let month = 0; month < 12; month++) {
  //     const monthName = new Date(currentYear, month).toLocaleString('default', { month: 'long' });
  //     months.push(`${monthName} ${currentYear}`);
  //     // months.push(`${currentYear}`)
  //   }
  //   return months
  // }
  getCurrencyIcon(currency?: string) {
    if (currency) {
      return getCurrencySymbol(currency, "narrow", this.locale);
    }
    let currencyIcon = '';
    this.authService.currentUser$.subscribe(async x => {
      if (x && x.currency) {
        currencyIcon = getCurrencySymbol(x.currency.name, "narrow", this.locale);
      } else {
        currencyIcon = getCurrencySymbol('USD', "narrow", this.locale);
      }
    })
    return currencyIcon
  }
  extractGoogleAddress(receivedAddresses: any) {
    let address_components = receivedAddresses.address_components;
    let address: any = {
      placeId: receivedAddresses.place_id,
      name: receivedAddresses.name,
      fullAddress: receivedAddresses.formatted_address,
      geometry: {
        lat: receivedAddresses.geometry.location.lat(),
        lng: receivedAddresses.geometry.location.lng(),
      },
    };
    let formattedAddressArr: any[] = [receivedAddresses.name];
    address_components.forEach((component: any) => {
      if (component.types.indexOf("street_number") > -1) {
        // set numero
        address.location = component.long_name;
      }
      if (component.types.indexOf("route") > -1) {
        // set voie
        address.route = component.long_name;
      }
      if (component.types.indexOf("sublocality_level_1") > -1) {
        // set ville
        address.locality = component.long_name;
        if (!formattedAddressArr.includes(component.long_name)) {
          formattedAddressArr.push(component.long_name);
        }
      }
      if (component.types.indexOf("locality") > -1) {
        // set ville
        address.locality = component.long_name;
        if (!formattedAddressArr.includes(component.long_name)) {
          formattedAddressArr.push(component.long_name);
        }
      }
      if (component.types.indexOf("administrative_area_level_3") > -1) {
        // set ville
        address.locality = component.long_name;
        if (!formattedAddressArr.includes(component.long_name)) {
          formattedAddressArr.push(component.long_name);
        }
      }
      if (component.types.indexOf("administrative_area_level_1") > -1) {
        // set region
        address.state = component.long_name;
        formattedAddressArr.push(component.long_name);
      }
      if (component.types.indexOf("country") > -1) {
        // set pays
        address.country = component.long_name;
        formattedAddressArr.push(component.long_name);
      }
      if (component.types.indexOf("postal_code") > -1) {
        // set code postal
        address.zip = component.long_name;
      }
    });
    // console.log(formattedAddressArr);
    let formattedAddress = formattedAddressArr.join(', ');
    // console.log(formattedAddress)
    address.formattedAddress = formattedAddress;
    if (receivedAddresses.icon) {
      address.icon = receivedAddresses.icon
    }
    if (receivedAddresses.icon_background_color) {
      address.iconBackgroundColor = receivedAddresses.icon_background_color
    }
    let photos: any[] = [];
    if (receivedAddresses.photos) {
      receivedAddresses.photos.forEach((element: any) => {
        photos.push(element.getUrl())
      });
    }
    address.photos = photos;
    if (receivedAddresses.rating) {
      address.rating = receivedAddresses.rating
    }
    if (receivedAddresses.user_ratings_total) {
      address.userRatingsTotal = receivedAddresses.user_ratings_total
    }
    if (receivedAddresses.types) {
      address.types = receivedAddresses.types
    }
    if (receivedAddresses.website) {
      address.website = receivedAddresses.website
    }
    if (receivedAddresses.url) {
      address.url = receivedAddresses.url
    }
    if (receivedAddresses.opening_hours) {
      address.openingHours = receivedAddresses.opening_hours.weekday_text
    }
    if (receivedAddresses.international_phone_number) {
      address.internationalPhoneNumber = receivedAddresses.international_phone_number
    }
    return address;
  }

  getPlaceIcons() {
    let baseUrl = 'https://maps.gstatic.com/mapfiles/place_api/icons/';
    let icons = {
      airport: baseUrl + 'airport-71.png',
      amusement: baseUrl + 'amusement-71.png',
      aquarium: baseUrl + 'aquarium-71.png',
      art_gallery: baseUrl + 'art_gallery-71.png',
      atm: baseUrl + 'atm-71.png',
      baby: baseUrl + 'baby-71.png',
      bank_dollar: baseUrl + 'bank_dollar-71.png',
      bank_euro: baseUrl + 'bank_euro-71.png',
      bank_pound: baseUrl + 'bank_pound-71.png',
      beach: baseUrl + 'beach-71.png',
      bicycle: baseUrl + 'bicycle-71.png',
      bowling: baseUrl + 'bowling-71.png',
      cafe: baseUrl + 'cafe-71.png',
      camping: baseUrl + 'camping-71.png',
      car_dealer: baseUrl + 'car_dealer-71.png',
      car_rental: baseUrl + 'car_rental-71.png',
      car_repair: baseUrl + 'car_repair-71.png',
      casino: baseUrl + 'casino-71.png',
      civic_building: baseUrl + 'civic_building-71.png',
      convenience: baseUrl + 'convenience-71.png',
      courthouse: baseUrl + 'courthouse-71.png',
      dentist: baseUrl + 'dentist-71.png',
      doctor: baseUrl + 'doctor-71.png',
      electronics: baseUrl + 'electronics-71.png',
      fitness: baseUrl + 'fitness-71.png',
      flower: baseUrl + 'flower-71.png',
      gas_station: baseUrl + 'gas_station-71.png',
      generic_business: baseUrl + 'generic_business-71.png',
      generic_recreational: baseUrl + 'generic_recreational-71.png',
      geocode: baseUrl + 'geocode-71.png',
      golf: baseUrl + 'golf-71.png',
      government: baseUrl + 'government-71.png',
      historic: baseUrl + 'historic-71.png',
      jewelry: baseUrl + 'jewelry-71.png',
      library: baseUrl + 'library-71.png',
      lodging: baseUrl + 'lodging-71.png',
      monument: baseUrl + 'monument-71.png',
      mountain: baseUrl + 'mountain-71.png',
      movies: baseUrl + 'movies-71.png',
      museum: baseUrl + 'museum-71.png',
      pet: baseUrl + 'pet-71.png',
      police: baseUrl + 'police-71.png',
      post_office: baseUrl + 'post_office-71.png',
      repair: baseUrl + 'repair-71.png',
      restaurant: baseUrl + 'restaurant-71.png',
      school: baseUrl + 'school-71.png',
      shopping: baseUrl + 'shopping-71.png',
      ski: baseUrl + 'ski-71.png',
      stadium: baseUrl + 'stadium-71.png',
      supermarket: baseUrl + 'supermarket-71.png',
      taxi: baseUrl + 'taxi-71.png',
      tennis: baseUrl + 'tennis-71.png',
      train: baseUrl + 'train-71.png',
      travel_agent: baseUrl + 'travel_agent-71.png',
      truck: baseUrl + 'truck-71.png',
      university: baseUrl + 'university-71.png',
      wine: baseUrl + 'wine-71.png',
      worship_christian: baseUrl + 'worship_christian-71.png',
      worship_general: baseUrl + 'worship_general-71.png',
      worship_hindu: baseUrl + 'worship_hindu-71.png',
      worship_islam: baseUrl + 'worship_islam-71.png',
      worship_jewish: baseUrl + 'worship_jewish-71.png',
      zoo: baseUrl + 'zoo-71.png',
    };
    return icons;
  }

  getPlaceTypes() {
    let placeTypes = [
      'accounting',
      'airport',
      'amusement_park',
      'aquarium',
      'art_gallery',
      'atm',
      'bakery',
      'bank',
      'bar',
      'beauty_salon',
      'bicycle_store',
      'book_store',
      'bowling_alley',
      'bus_station',
      'cafe',
      'campground',
      'car_dealer',
      'car_rental',
      'car_repair',
      'car_wash',
      'casino',
      'cemetery',
      'church',
      'city_hall',
      'clothing_store',
      'convenience_store',
      'courthouse',
      'dentist',
      'department_store',
      'doctor',
      'drugstore',
      'electrician',
      'electronics_store',
      'embassy',
      'fire_station',
      'florist',
      'funeral_home',
      'furniture_store',
      'gas_station',
      'gym',
      'hair_care',
      'hardware_store',
      'hindu_temple',
      'home_goods_store',
      'hospital',
      'insurance_agency',
      'jewelry_store',
      'laundry',
      'lawyer',
      'library',
      'light_rail_station',
      'liquor_store',
      'local_government_office',
      'locksmith',
      'lodging',
      'meal_delivery',
      'meal_takeaway',
      'mosque',
      'movie_rental',
      'movie_theater',
      'moving_company',
      'museum',
      'night_club',
      'painter',
      'park',
      'parking',
      'pet_store',
      'pharmacy',
      'physiotherapist',
      'plumber',
      'police',
      'post_office',
      'primary_school',
      'real_estate_agency',
      'restaurant',
      'roofing_contractor',
      'rv_park',
      'school',
      'secondary_school',
      'shoe_store',
      'shopping_mall',
      'spa',
      'stadium',
      'storage',
      'store',
      'subway_station',
      'supermarket',
      'synagogue',
      'taxi_stand',
      'tourist_attraction',
      'train_station',
      'transit_station',
      'travel_agency',
      'university',
      'veterinary_care',
      'zoo'
    ]
    return placeTypes;
  }
  formatNumber(n: number, d: number) {
    if (n < 1000 && n > -1000) {
      return this.convertNumberToDecimalPlaces(n);
    }
    let si = [
      { v: 1E3, s: "K" },
      { v: 1E6, s: "M" },
      { v: 1E9, s: "B" },
      { v: 1E12, s: "T" },
      { v: 1E15, s: "P" },
      { v: 1E18, s: "E" }
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (n >= si[index].v) {
        break;
      }
    }
    return (n / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
  }

  convertNumberToDecimalPlaces(value: number, dp: number = 2) {
    const options = {
      style: 'decimal', // Can be 'decimal' (default), 'currency', or 'percent'
      minimumFractionDigits: Number.isInteger(value) ? 0 : 2,
      maximumFractionDigits: 2
    };
    const formattedNumber = new Intl.NumberFormat('en-US', options).format(value);
    return formattedNumber;
  }

  generateRandomNumber() {
    let time = new Date().getTime();
    let randomNumber = time & 0xfffff;
    return randomNumber;
  }

  private changeView: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  changeView$ = this.changeView.asObservable();

  setView(view: string) {
    this.changeView.next(view);
  }

  public cachedImages: { [url: string]: string } = {};

  getMonths(currentYear: number) {
    const currentMonth = new Date().getMonth();
    let months: any[] = [];
    for (let i = 0; i <= currentMonth; i++) {
      let month: any = {
        monthNo: i,
        monthName: new Date(currentYear, i).toLocaleString('default', { month: 'long' }),
        year: currentYear,
      }
      months.unshift(month);
    }
    return months;
  }

  getMonthsBetweenDates(startDate: Date, endDate: Date) {
    let months = [];
    let startMonth = startDate.getMonth();
    let startYear = startDate.getFullYear();
    let endMonth = endDate.getMonth();
    let endYear = endDate.getFullYear();
    let currentDateObject = new Date(startYear, startMonth);
    let endDateObject = new Date(endYear, endMonth);
    while (currentDateObject <= endDateObject) {
      const year = currentDateObject.getFullYear();
      const month = currentDateObject.getMonth();
      let details = {
        month: month,
        monthName: new Date(year, month).toLocaleString('default', { month: 'long' }),
        year: year,
      }
      months.unshift(details);
      currentDateObject.setMonth(month + 1);
    }
    return months;
  }

}
